const mutations = {
  SET_AUTOMATION_WORKFLOW: (state, payload) => {
    if(!payload?.published && payload) {
      payload.published = state?.workflow?.published
    }
    state.workflow = payload;
    if(!state.selectedWorkflowVersion && payload) {
      state.selectedWorkflowVersion = state.workflow.published;
    }
  },
  UPDATE_AUTOMATION_WORKFLOW: (state, payload) => {
    state.workflow = {...state.workflow, ...payload};
  },
  SET_FETCH_AUTOMATION_WORKFLOW_LOADING: (state, payload) => {
    state.fetchWorkflowLoading = payload;
  },
  SET_SELECTED_NODE: (state, payload) => {
    state.selectedNode = payload;
  },
  SET_SELECTED_NODE_OPTIONS: (state, payload) => {
    state.selectedNodeOptions = payload;
  },
  SET_START_SETTINGS: (state, payload) => {
    state.startSettings = {
      ...state.startSettings,
      ...payload,
    };
  },
  ADD_CREDENTIAL: (state, payload) => {
    state.credentials = [...state.credentials, payload];
  },
  DELETE_CREDENTIAL: (state, id) => {
    state.credentials = state.credentials.filter((credId) => credId !== id);
  },
  SET_CREDENTIALS: (state, payload) => {
    state.credentials = payload;
  },
  SET_OUTPUT_MODELS: (state, payload) => {
    state.actionsOutputs = payload;
  },
  SET_NODES: (state, payload) => {
    state.nodes = payload;
  },
  SET_COPY_OF_SAVED_NODES_NODES: (state, payload) => {
    state.copyOfSavedNodes = payload;
  },
  SET_MOVING_NODE_ID: (state, payload) => {
    state.movingNodeId = payload;
  },
  EDIT_WORKFLOW_PROPERTY: (state, { key, value }) => {
    state.workflow = {
      ...state.workflow,
      [key]: value,
    };
  },
  WORKFLOW_EDITED: (state, payload = true) => {
    state.workflowEdited = payload;
  },
  SET_INPUT_TASK_QUESTIONS: (state, payload) => {
    state.inputTaskQuestions = payload;
  },
  SET_SET_INPUT_TASK_LOADING: (state, payload) => {
    state.fetchWorkflowLoading = payload;
  },
  SET_SAVE_WORKFLOW_LOADING: (state, payload) => {
    state.saveWorkflowLoading = payload;
  },
  SET_UPDATE_WORKFLOW_UPDATING: (state, payload) => {
    state.updateWorkflowLoading = payload;
  },
  SET_FETCH_OUTPUT_MODELS_LOADING: (state, payload) => {
    state.fetchOutputModelsLoading = payload;
  },
  SET_APPOINTMENT_OPTIONS: (state, payload) => {
    state.appointmentOptions = payload;
  },
  SET_FETCH_APPOINTMENT_OPTIONS_LOADING: (state, payload) => {
    state.fetchAppointmentOptionsLoading = payload;
  },
  SET_WORKFLOW_RUNNING_OBJ: (state, payload = {}) => {
    const { workflowId, ...running } = payload;

    state.runStatus = {
      ...state.runStatus,
      [workflowId]: {
        ...state.runStatus[workflowId],
        ...running,
      },
    };
  },
  SET_START_WORKFLOW_LOADING: (state, payload) => {
    state.startWorkflowLoading = payload;
  },
  SET_RUN_AUTOMATION_WORKFLOW_LOADING: (state, payload) => {
    state.runWorkflowLoading = payload;
  },
  SET_CHECK_AUTOMATION_WORKFLOW_STATUS_LOADING: (state, payload) => {
    state.checkingAutomationWorkflowStatusLoading = payload;
  },
  SET_EXECUTE_SINGLE_ACTION_LOADING: (state, payload) => {
    state.executeSingleActionLoading = payload;
  },
  SET_ALERT_SETTINGS: (state, payload) => {
    state.alertSettings = payload;
  },
  SET_S3_STORAGE_ENABLED: (state, payload) => {
    state.s3StorageEnabled = payload;
  },
  SET_WORKFLOW_VERSIONS: (state, payload) => {
    state.workflowVersions = payload;
  },
  SET_WORKFLOW_VERSIONS_LOADING: (state, payload) => {
    state.workflowVersionsLoading = payload;
  },
  SET_PUBLISH_WORKFLOW_VERSION_LOADING: (state,payload) => {
    state.publishWorkflowVersionLoading = payload;
  },
  SET_SELECT_WORKFLOW_VERSION_LOADING: (state,payload) => {
    state.selectWorkflowVersionLoading = payload;
  },
  SET_UPDATE_WORKFLOW_VERSION_LOADING: (state, payload) => {
   state.updateWorkflowVersionLoading = payload;
  },
  SET_DELETE_WORKFLOW_VERSION_LOADING: (state, payload) => {
    state.deleteWorkflowVersionLoading = payload;
   },
  SET_WORKFLOW_SELECT_VERSION: (state, payload) => {
    state.selectedWorkflowVersion = payload;
    state.selectedCommit = payload?.commits ? payload?.commits[0] : null
  },
  UPDATE_WORKFLOW_VERSION_LIST: (state, payload) => {
    const versions = state.workflowVersions;
    const index = versions.findIndex((item) => item.id === payload.id);
    if(index === -1) {
      state.workflowVersions.unshift(payload);
      return;
    }
    state.workflowVersions[index] = payload;
  },
  SET_WORKFLOW_PUBLISHED_VERSION: (state, payload) => {
    state.workflow.published = payload;
  },
  SET_OPEN_VERSION_MODAL_FROM_OVERWRITE: (state, payload)=>{
    state.openVersionModalFromOverwrite = payload;
  },
  SET_OPEN_VERSION_MODAL_FROM_RUN: (state, payload)=>{
    state.openVersionModalFromRun = payload;
  },
  SET_OVERWRITE_VERSION_LOADING: (state, payload)=>{
    state.overwriteVersionLoading = payload;
  },
  SET_SELECTED_COMMIT: (state, payload) => {
    state.selectedCommit = payload;
  },
  SET_RESTORE_COMMIT_LOADING: (state, payload) => {
    state.restoreCommitLoading = payload;
  },
  SET_HIDE_SETTINGS: (state, payload) => {
    state.hideSettings = payload;
  },
};

export default mutations;
