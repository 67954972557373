import Cookies from "js-cookie";

const updateUserAICard = async ({ rootGetters, dispatch }, payload) => {
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
        const data = { ...payload, userId: Cookies.get('userId'), customer_id: customer.customer_id };

        if (customer.customer_id === 0) {
            data.customer_id = selected_customer.customer_id;
        }

        const response = await window.axios.put(`/proxy/ai/settings/${data.userId}/user-ai-card?customer_id=${data.customer_id}`, data);
        if (response && response.data.success) {
            return { success: true };
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
    }
};

export default updateUserAICard;