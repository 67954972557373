import cloneDeep from 'lodash/cloneDeep';
import {
  omitUIFields,
  convertToObject,
  generateTrigger,
} from '@/util/action-types';
import { workflowStatus, defaultRunning } from '@/util/workflow-statuses';

const runWorkflow = async (
  { dispatch, commit, getters },
  { isProduction, isNew }
) => {
  const running = cloneDeep({
    ...defaultRunning,
    runningInProductionMode: isProduction,
  });
  let workflowId;

  try {
    const startSettings = getters['START_SETTINGS'];
    const isCronJob = startSettings.trigger_type === 'cron';
    const schedule = generateTrigger(startSettings);

    if (isCronJob && !schedule) {
      throw new Error(
        'Please check you set all start settings (name, periodicity, time at, and so on)'
      );
    }

    let saveUpdateResult;

    if (isNew) {
      saveUpdateResult = await dispatch('saveWorkflow', { loader: true });
    } else {
      saveUpdateResult = await dispatch('updateWorkflow', { loader: true });
    }

    if (!saveUpdateResult.success) {
      throw new Error(`Unable to ${isNew ? 'save' : 'update'} workflow`);
    }
    workflowId = saveUpdateResult.workflowId;

    commit(
      'SET_WORKFLOW_RUNNING_OBJ',
      cloneDeep({
        ...running,
        workflowId,
      })
    );

    const nodeList = getters['NODES'];
    const nodes = omitUIFields(nodeList);
    const nodesObj = convertToObject(nodes);

    const validationOptions = {
      schedule,
      actions: nodesObj,
    };

    const validationRes = await dispatch('validateActions', validationOptions);

    running.validation.actions = validationRes.data.actions_valid
      ? workflowStatus.SUCCESS
      : workflowStatus.FAILURE;

    if (isCronJob) {
      running.validation.schedule = validationRes.data.schedule_valid
        ? workflowStatus.SUCCESS
        : workflowStatus.FAILURE;
    } else {
      running.validation.schedule = workflowStatus.SKIPPED;
    }

    if (validationRes.data.actions_error) {
      running.validation.validation_status = workflowStatus.FAILURE;
      running.workflow_status = workflowStatus.FAILURE;

      throw new Error(validationRes.data.actions_error);
    }

    running.validation.validation_status = workflowStatus.SUCCESS;

    commit('SET_WORKFLOW_RUNNING_OBJ', cloneDeep({ ...running, workflowId }));
    await dispatch('checkVmStatus', { workflowId });
    await dispatch('startWorkflow', { production: isProduction, workflowId });
  } catch (error) {
    running.workflow_status = workflowStatus.FAILURE;
    commit('SET_WORKFLOW_RUNNING_OBJ', cloneDeep({ ...running, workflowId }));
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_RUN_AUTOMATION_WORKFLOW_LOADING', false);
  }
};

export default runWorkflow;
