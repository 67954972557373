import Cookies from "js-cookie";

const refreshAdvancedWorkspaceKnowledge = async ({ commit, rootGetters, dispatch }, payload) => {
    commit("SET_WORKSPACE_KNOWLEDGE_FILES_LOADING", true);
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
        const data = { ...payload, customer_id: customer.customer_id, userId: Cookies.get('userId') };

        if (customer.customer_id === 0) {
            data.customer_id = selected_customer.customer_id;
        }

        let reqURL = `/proxy/ai/workspaces/${data.workspaceId}/knowledge/refresh-advanced?customer_id=${data.customer_id}`;

        const response = await window.axios.put(reqURL, data);
        if (response && response.data.success) {
            return response.data;
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
    } finally {
        commit("SET_WORKSPACE_KNOWLEDGE_FILES_LOADING", false);
    }
};

export default refreshAdvancedWorkspaceKnowledge;