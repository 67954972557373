const getters = {
  WORKFLOW: ({ workflow }) => workflow || {},
  SELECTED_NODE: ({ selectedNode }) => selectedNode,
  START_SETTINGS: ({ startSettings }) => startSettings,
  CREDENTIALS: ({ credentials }) => credentials,
  ACTION_OUTPUTS: ({ actionsOutputs }) => actionsOutputs,
  NODES: ({ nodes }) => nodes,
  COPY_OF_SAVED_NODES: ({ copyOfSavedNodes }) => copyOfSavedNodes,
  MOVING_NODE_ID: ({ movingNodeId }) => movingNodeId,
  SET_INPUT_TASK_LOADING: ({ setInputTaskLoading }) => setInputTaskLoading,
  INPUT_TASK_QUESTIONS: ({ inputTaskQuestions }) => inputTaskQuestions,
  SAVE_WORKFLOW_LOADING: ({ saveWorkflowLoading }) => saveWorkflowLoading,
  UPDATE_WORKFLOW_LOADING: ({ updateWorkflowLoading }) => updateWorkflowLoading,
  FETCH_OUTPUT_MODELS_LOADING: ({ fetchOutputModelsLoading }) =>
    fetchOutputModelsLoading,
  APPOINTMENT_OPTIONS: ({ appointmentOptions }) => appointmentOptions,
  FETCH_APPOINTMENT_OPTIONS_LOADING: ({ fetchAppointmentOptionsLoading }) =>
    fetchAppointmentOptionsLoading,
  WORKFLOW_EDITED: ({ workflowEdited }) => workflowEdited,
  SELECTED_NODE_OPTIONS: ({ selectedNodeOptions }) => selectedNodeOptions,
  CAN_WORKFLOW_BE_SAVED: ({ startSettings, workflow = {} }) =>
    workflow && workflow.name && startSettings.trigger_type,
  WORKFLOW_RUNNING_OBJ:
    ({ runStatus }) =>
    (id) =>
      runStatus[id] || {},
  WORKFLOW_RUNNING: ({ runWorkflowLoading }) => runWorkflowLoading,
  WORKFLOW_STARTED: ({ startWorkflowLoading }) => startWorkflowLoading,
  CHECK_AUTOMATION_WORKFLOW_STATUS_LOADING: ({
    checkingAutomationWorkflowStatusLoading,
  }) => checkingAutomationWorkflowStatusLoading,
  EXECUTE_SINGLE_ACTION_LOADING: ({ executeSingleActionLoading }) =>
    executeSingleActionLoading,
  IS_SERVERLESS: ({ workflow }) => workflow?.isServerless || false,
  ALERT_SETTINGS: ({ alertSettings }) => alertSettings,
  S3_STORAGE_ENABLED: ({ s3StorageEnabled }) => s3StorageEnabled,
  WORKFLOW_VERSIONS: ({ workflowVersions }) => workflowVersions || [],
  WORKFLOW_VERSIONS_LOADING: ({ workflowVersionsLoading }) =>
    workflowVersionsLoading,
  PUBLISH_WORKFLOW_VERSION_LOADING: ({ publishWorkflowVersionLoading }) =>
    publishWorkflowVersionLoading,
  SELECT_WORKFLOW_VERSION_LOADING: ({ selectWorkflowVersionLoading }) =>
    selectWorkflowVersionLoading,
  UPDATE_WORKFLOW_VERSION_LOADING: ({ updateWorkflowVersionLoading }) =>
    updateWorkflowVersionLoading,
  DELETE_WORKFLOW_VERSION_LOADING: ({ deleteWorkflowVersionLoading }) =>
    deleteWorkflowVersionLoading,
  WORKFLOW_PUBLISHED_VERSION: ({ workflow }) => workflow?.published || {},
  WORKFLOW_SELECTED_VERSION: ({ selectedWorkflowVersion, workflow }) =>
    selectedWorkflowVersion || workflow?.published || {},
  WORKFLOW_SELECTED_COMMIT: ({ selectedCommit, workflow }) => {
    if (selectedCommit) {
      return selectedCommit;
    }
    if (workflow?.published?.commits?.length > 0) {
      return workflow?.published?.commits[
        workflow?.published?.commits?.length - 1
      ];
    }
    return {};
  },
  RESTORE_COMMIT_LOADING: ({ restoreCommitLoading }) => restoreCommitLoading,
  GET_HIDE_SETTINGS: ({ hideSettings }) => hideSettings,
};

export default getters;
