import {actionsTypes, addUIFields} from "@/util/action-types";

const getWorkspace = async ({ commit, rootGetters, dispatch }, payload) => {
    if(!payload.skipLoading) {
        commit("SET_WORKSPACE_LOADING", true);
    }
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
        const data = { ...payload, customer_id: customer.customer_id };

        if (customer.customer_id === 0) {
            data.customer_id = selected_customer.customer_id;
        }

        const response = await window.axios.get(`/proxy/ai/workspaces/${data.workspaceId}?customer_id=${data.customer_id}`, data);
        if (response && response.data.success) {
            if(!payload.skipLoading) {
                commit('SET_WORKSPACE', response.data.workspace);
                commit('SET_GENERAL_ACTIONS', addUIFields(response.data?.workspace?.general_actions || [], actionsTypes()));
            }
            return response.data.workspace;
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
    }
};

export default getWorkspace;